import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './pages/navbar/Navbar';
import Login from "./pages/auth/Login";
import CountryList from "./pages/masters/CountryList";
import StateList from "./pages/masters/StateList";
import City from "./pages/masters/City";
import MasterDataList from "./pages/masters/MasterDataList";
import MemberShipPlans from "./pages/masters/MembershipPlans";
import ServiceList from "./pages/services/ServiceList";
import ServiceOfferingsList from './pages/services/ServiceOfferingsList';
import ServiceOfferingsForm from './pages/services/ServiceOfferingsForm';
import VendorList from "./pages/user/VendorList";
import Staff from "./pages/user/Staff";
import StaffForm from "./pages/user/StaffForm";
import Enduser from "./pages/user/EndUser";
import UserDetails from "./pages/user/UserDetails";
import VendortSignUp from "./pages/user/VendorSignUp";
import Vendordetails from "./pages/user/VendorDetails";
import InquirieList from "./pages/inquiries/InquirieList";
import InquiryDetails from "./pages/inquiries/InquiryDetails";
import CouponsList from "./pages/coupon/CouponsList";
import CouponsForm from "./pages/coupon/CouponsForm";
import CouponDetails from './pages/coupon/CouponDetails';
import AdsList from "./pages/ads/AdsList";
import AdsForm from "./pages/ads/AdsForm";
import BookingsList from "./pages/bookings/BookingsList";
import BookingsDetails from "./pages/bookings/BookingsDetails";
import ChatApp from "./pages/chat/ChatApp";
import NoPage from "./pages/NoPage";
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import * as apicall from './components/Apicall';
import * as constants from './constants/Constants';

const pubnub = new PubNub({
    publishKey: constants.pub_nub_publishKey,
    subscribeKey: constants.pub_nub_subscribeKey,
    uuid: constants.pub_nub_uuid,
});

function App() {
    const { pathname } = useLocation();
    const isLoggedIn = !!localStorage.getItem('token');
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [notifications, setNotifications] = useState({});
    const [currentChannel, setCurrentChannel] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            const fetchChannels = async () => {
                try {
                    const res = await apicall.get("admin_chatInfo");
                    const channels = res.data.map(user => user.pubnub_channel_id);
                    pubnub.subscribe({ channels });

                    pubnub.addListener({
                        message: (event) => handleNewMessage(event),
                    });

                    const hasUnread = Object.values(notifications).some(count => count > 0);
                    setHasUnreadMessages(hasUnread);
                } catch (error) {
                    console.error("Error fetching channels:", error);
                }
            };

            fetchChannels();

            return () => {
                pubnub.unsubscribeAll();
            };
        }
    }, [isLoggedIn]);

    const handleNewMessage = (event) => {
        const { channel, publisher } = event;

        if (publisher === pubnub.getUUID()) {
            return;
        }

        if (!pathname.startsWith("/chat")) {
            toast.info("New message received");
        }

        setNotifications((prevNotifications) => {
            const updatedNotifications = {
                ...prevNotifications,
                [channel]: (prevNotifications[channel] || 0) + 1,
            };

            setHasUnreadMessages(true);
            return updatedNotifications;
        });
    };

    const resetNotifications = (channel = null) => {
        setNotifications((prevNotifications) => {
            if (channel) {
                const { [channel]: _, ...remainingNotifications } = prevNotifications;
                setHasUnreadMessages(Object.values(remainingNotifications).some(count => count > 0));
                return remainingNotifications;
            } else {
                setHasUnreadMessages(false);
                return {};
            }
        });
    };

    useEffect(() => {
        if (pathname.startsWith("/chat") && currentChannel) {
            resetNotifications(currentChannel);
        }
    }, [pathname, currentChannel]);

    const wrapPrivateRoute = (element) => (
        <PrivateRoute>{element}</PrivateRoute>
    );

    return (
        <PubNubProvider client={pubnub}>
            <div>
                {!pathname.startsWith("/chat") && (
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                )}
                {isLoggedIn && pathname !== "/login" && (
                    <Navbar
                        hasUnreadMessages={hasUnreadMessages}
                        resetNotifications={() => resetNotifications()}
                    />
                )}
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    {isLoggedIn && (
                        <>
                            <Route path="/services" element={wrapPrivateRoute(<ServiceList />)} />
                            <Route
                                path="/service_offerings/:service_id"
                                element={wrapPrivateRoute(<ServiceOfferingsList />)}
                            />
                            <Route
                                path="/service_offerings_form/:offerings_id"
                                element={wrapPrivateRoute(<ServiceOfferingsForm />)}
                            />
                            <Route
                                path="/vendors"
                                element={wrapPrivateRoute(<VendorList />)}
                            />
                            <Route
                                path="/vendor/:vendor_id"
                                element={wrapPrivateRoute(<VendortSignUp />)}
                            />
                            <Route
                                path="/vendorDetails/:vendor_id"
                                element={wrapPrivateRoute(<Vendordetails />)}
                            />
                            <Route path="/staff" element={wrapPrivateRoute(<Staff />)} />
                            <Route
                                path="/staffForm/:id"
                                element={wrapPrivateRoute(<StaffForm />)}
                            />
                            <Route path="/user" element={wrapPrivateRoute(<Enduser />)} />
                            <Route
                                path="/userDetails/:id"
                                element={wrapPrivateRoute(<UserDetails />)}
                            />
                            <Route
                                path="/inquiries"
                                element={wrapPrivateRoute(<InquirieList />)}
                            />
                            <Route
                                path="/inquiry/:id"
                                element={wrapPrivateRoute(<InquiryDetails />)}
                            />
                            <Route path="/coupons" element={wrapPrivateRoute(<CouponsList />)} />
                            <Route
                                path="/coupon/:id"
                                element={wrapPrivateRoute(<CouponsForm />)}
                            />
                            <Route
                                path="/couponDetails/:id"
                                element={wrapPrivateRoute(<CouponDetails />)}
                            />
                            <Route path="/ads" element={wrapPrivateRoute(<AdsList />)} />
                            <Route path="/adsform/:id" element={wrapPrivateRoute(<AdsForm />)} />
                            <Route path="/country" element={wrapPrivateRoute(<CountryList />)} />
                            <Route path="/state" element={wrapPrivateRoute(<StateList />)} />
                            <Route path="/city" element={wrapPrivateRoute(<City />)} />
                            <Route
                                path="/memberShip"
                                element={wrapPrivateRoute(<MemberShipPlans />)}
                            />
                            <Route
                                path="/masterData"
                                element={wrapPrivateRoute(<MasterDataList />)}
                            />
                            <Route path="/bookings" element={wrapPrivateRoute(<BookingsList />)} />
                            <Route
                                path="/bookingDetails/:id"
                                element={wrapPrivateRoute(<BookingsDetails />)}
                            />
                            <Route
                                path="/chat"
                                element={
                                    <PrivateRoute>
                                        <ChatApp
                                            resetNotifications={resetNotifications}
                                            setCurrentChannel={setCurrentChannel}
                                        />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="/404" element={wrapPrivateRoute(<NoPage />)} />
                        </>
                    )}
                </Routes>
            </div>
        </PubNubProvider>
    );
}

export default App;