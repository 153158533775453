import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ListGroup, Form, Button, InputGroup } from 'react-bootstrap';
import { usePubNub } from 'pubnub-react';
import * as apicall from '../../components/Apicall';

const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'];
const getColor = () => colors[Math.floor(Math.random() * colors.length)];

const timeFormate = (time) => {
  const timestampInMs = time / 10000;
  const messageDate = new Date(timestampInMs);
  return messageDate
    .toLocaleString([], {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(' at ', ' ');
};

const ChatApp = () => {
  const scrollRef = useRef(null);
  const pubnub = usePubNub();
  const listenerInitialized = useRef(false);

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeUser, setActiveUser] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notificationsState, setNotifications] = useState({});
  const [activeChannel, setActiveChannel] = useState(null);

  const getUsersList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await apicall.get('admin_chatInfo');
      const channelsListArray = res.data.map((user) => user.pubnub_channel_id);

      connectPubNub(channelsListArray);

      const usersList = await Promise.all(
        res.data.map(async (user) => {
          const messages = [];
          const msgResponse = await pubnub.fetchMessages({
            channels: [user.pubnub_channel_id],
            includeMessageActions: true,
            count: 100,
          });
          const message = msgResponse.channels[user.pubnub_channel_id];
          let startTimeToken = '';

          if (message && message.length > 0) {
            const fetchedMessages = message.map((msg) => ({
              sender: msg.uuid === user.pubnub_uuid ? 1 : 2,
              message: msg.message.description,
              time: timeFormate(msg.timetoken),
              timetoken: msg.timetoken,
            }));
            messages.push(...fetchedMessages);
            startTimeToken = message[message.length - 1]?.timetoken || '';
          }

          return {
            id: user.id,
            pubnub_uuid: user.pubnub_uuid,
            pubnub_channel_id: user.pubnub_channel_id,
            name: user.first_name,
            initial: user.initial,
            color: getColor(),
            messages,
            startTimeToken,
          };
        })
      );

      const sortedList = usersList.sort((a, b) => b.startTimeToken - a.startTimeToken);

      setUsers(sortedList);
      setActiveUser(sortedList[0]);
    } catch (e) {
      console.error('Failed to fetch user list:', e);
    } finally {
      setLoading(false);
    }
  }, [pubnub]);

  const connectPubNub = useCallback(
    (channels) => {
      const newChannels = channels.filter(
        (channel) => !pubnub.getSubscribedChannels().includes(channel)
      );

      if (newChannels.length > 0) {
        pubnub.subscribe({ channels: newChannels });
      }
      if (!listenerInitialized.current) {
        pubnub.addListener({
          message: handleIncomingMessage,
        });
        listenerInitialized.current = true;
      }
    },
    [pubnub]
  );

  const handleIncomingMessage = (messageData) => {
    if (messageData.publisher === pubnub.getUUID()) return;
  
    setUsers((prevUsers) => {
      const userList = [...prevUsers];
      const index = userList.findIndex((user) => user.pubnub_channel_id === messageData.channel);
  
      if (index !== -1) {
        const newMessage = {
          sender: messageData.publisher === userList[index].pubnub_uuid ? 1 : 2,
          message: messageData.message.description,
          time: timeFormate(messageData.timetoken),
          timetoken: messageData.timetoken,
        };
  
        if (!userList[index].messages.some((msg) => msg.timetoken === newMessage.timetoken)) {
          userList[index].messages.push(newMessage);
          const updatedUser = userList.splice(index, 1)[0];
          userList.unshift(updatedUser);
        }
  
        if (activeChannel === messageData.channel) {
          resetNotificationsLocal(activeChannel);
          setTimeout(() => {
            scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 100); // Ensure DOM updates before scrolling
        } else {
          setNotifications((prev) => ({
            ...prev,
            [messageData.channel]: (prev[messageData.channel] || 0) + 1,
          }));
        }
      }
  
      return userList;
    });
  };
  
  
  useEffect(() => {
    if (activeUser?.messages.length > 0) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0); 
    }
  }, [activeUser?.messages]);
  
  
  
  const handleUserClick = (user) => {
    setActiveUser(user);
    setActiveChannel(user.pubnub_channel_id);
    resetNotificationsLocal(user.pubnub_channel_id);
  };

  const resetNotificationsLocal = (channel) => {
    setNotifications((prev) => ({
      ...prev,
      [channel]: 0,
    }));
  };
  


  const filteredData = useMemo(() => {
    return users.filter((user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, users]);

  const publishMessage = () => {
    if (newMessage.trim() && activeUser) {
      const tempMessage = {
        sender: 2,
        message: newMessage,
        time: timeFormate(Date.now() * 10000),
        timetoken: `${Date.now()}0000000`,
      };

      setUsers((prevUsers) => {
        const userList = [...prevUsers];
        const index = userList.findIndex((user) => user.id === activeUser.id);
        if (index !== -1) {
          userList[index].messages.push(tempMessage);
          const updatedUser = userList.splice(index, 1)[0];
          userList.unshift(updatedUser);
        }
        return userList;
      });

      pubnub.publish({
        channel: activeUser.pubnub_channel_id,
        message: { description: newMessage },
      }).then(() => {
        setNewMessage('');
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
    }
  };

  useEffect(() => {
    getUsersList();

    return () => {
      pubnub.unsubscribeAll();
      pubnub.removeAllListeners();
    };
  }, [getUsersList, pubnub]);

  useEffect(() => {
    if (activeUser?.pubnub_channel_id) {
      resetNotificationsLocal(activeUser.pubnub_channel_id);
    }
  }, [activeUser, users]); 
  

  useEffect(() => {
    if (activeUser && activeUser.messages.length > 0) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeUser?.messages]);

  return (
    <div style={{ overflow: 'hidden', height: '87vh' }}>
      {!loading ? (
        <div className="d-flex">
          <UserList
            users={filteredData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            activeUser={activeUser}
            handleUserClick={handleUserClick}
            notificationsState={notificationsState}
          />
          <ChatArea
            activeUser={activeUser}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            publishMessage={publishMessage}
            scrollRef={scrollRef}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '88vh' }}>
          Loading...
        </div>
      )}
    </div>
  );
};

const UserList = ({ users, searchTerm, setSearchTerm, activeUser, handleUserClick, notificationsState }) => (
  <div className="col-3 border-end p-3 bg-white" style={{ overflowY: 'auto', height: '88vh' }}>
    <Form.Group className="mb-3">
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Search for users"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
    </Form.Group>
    <ListGroup variant="flush">
      {users.map((user) => (
        <ListGroup.Item
          key={user.id}
          action
          onClick={() => handleUserClick(user)}
          active={user.id === activeUser?.id}
          className="user-item"
          style={{
            backgroundColor: user.id === activeUser?.id ? '#ffe4e4' : '',
            color: 'black',
          }}
        >
          <div className="d-flex align-items-center m-1">
            <div
              className={`bg-${user.color} text-white d-flex align-items-center justify-content-center`}
              style={{
                width: 40,
                height: 40,
                borderRadius: '5px',
                margin: '0 10px',
              }}
            >
              {user.initial}
            </div>
            <div className="ms-2">
              <p className="mb-0 small mt-1">{user.name}</p>
              <p
                className={`small mb-0 ${notificationsState[user.pubnub_channel_id] > 0 &&
                    user.pubnub_channel_id !== activeUser?.pubnub_channel_id
                    ? 'fw-bold'
                    : 'text-muted'
                  }`}
              >
                {user.messages.length > 0
                  ? user.messages[user.messages.length - 1].message.length > 30
                    ? user.messages[user.messages.length - 1].message.substring(0, 25) + '...'
                    : user.messages[user.messages.length - 1].message
                  : 'No messages yet'}
              </p>


            </div>
            {notificationsState[user.pubnub_channel_id] > 0 && user.pubnub_channel_id !== activeUser?.pubnub_channel_id && (
              <span className="badge bg-danger ms-auto">
                {notificationsState[user.pubnub_channel_id]}
              </span>
            )}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </div>
);

const ChatArea = ({ activeUser, newMessage, setNewMessage, publishMessage, scrollRef }) => (
  <div className="col-9 d-flex flex-column bg-white">
    <div className="border-bottom p-3 d-flex justify-content-between align-items-center">
      <h5 className="mb-0">{activeUser?.name}</h5>
    </div>
    <div className="flex-grow-1 p-3 overflow-auto" style={{ maxHeight: '70vh' }}>
      {activeUser?.messages.map((msg) => (
        <div
          key={msg.timetoken}
          className={`d-flex flex-column align-items-${msg.sender === 2 ? 'end' : 'start'} mb-3`}
        >
          <div
            className={`p-2 w-50 ${msg.sender === 2 ? 'primaryBackground text-white' : 'bg-light'}`}
            style={{
              borderRadius: '15px 15px 0 15px',
              wordWrap: 'break-word',
            }}
          >
            <div>
              <p
                className={`mb-1 fw-bold ${msg.sender === 2 ? 'text-white' : 'primaryColorText'}`}
              >
                {msg.sender === 2 ? 'Admin' : activeUser?.name}
              </p>
            </div>
            <p className="mb-0">{msg.message}</p>
            <p
              className={`mb-0 small mt-1 text-end ${msg.sender === 2 ? 'text-white' : 'text-dark'}`}
            >
              {msg.time}
            </p>
          </div>
        </div>
      ))}
      <div ref={scrollRef}></div>
    </div>
    <div className="border-top p-3">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (newMessage.trim()) publishMessage();
        }}
      >
        <InputGroup>
          <Form.Control
            placeholder="Write your message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <Button
            type="submit"
            className="primaryBackground border-0"
            aria-label="Send message"
          >
            ➤
          </Button>
        </InputGroup>
      </Form>
    </div>
  </div>
);

export default ChatApp;