import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import Table from '../../components/Table';
import { Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as apicall from '../../components/Apicall';

const AdsList = () => {

    const location = useLocation();
    const navigate = useNavigate();
  
    const [ads, setAds] = useState([]);
    const [loadingAds, setLoadingAds] = useState(true);
    const [searchTerm, setSearchTerm] = useState(location.state?.searchKey || '');
    const [statusFilter, setStatusFilter] = useState(location.state?.statusFilter || null);
    const [filterApplied, setFilterApplied] = useState(Boolean(location.state?.statusFilter || location.state?.searchKey));
    const [showFilters, setShowFilters] = useState(location.state?.showFilters || false);
    const [role, setRole] = useState(localStorage.getItem("role"));

  
  const adHeaders = [
    ' Name',
    'Description',
    'Start Date',
    'End Date',
    'URL',
    'Is Active',
  ];

  const statusList = [{ "name": "Active", "value": 1 }, { "name": "Inactive", "value": 0 }];

  const getAdsList = async (data) => {
    setLoadingAds(true);
    try {
      const res = await apicall.post(data, 'get_all_ads');
      if (res.success) {
        setAds(res.data);
      } else {
        toast.error('Failed to fetch ads.');
      }
    } catch (e) {
      toast.error(e.message.replace('Error:', ''));
    } finally {
      setLoadingAds(false);
    }
  };

  /*useEffect(() => {
    getAdsList({});
  }, []); */

  useEffect(() => {
    if (searchTerm == "") {
      applyFilters();
    }
  }, [searchTerm]);

  const handleEdit = (index) => {
    const ad = ads[index];
    navigate(`/adsform/${ad.id}`, {
      state: {
        id: ad.id, 
        adData: ad, 
        searchTerm,
        statusFilter,
        filterApplied,
      },
    });
  };

  const applyFilters = () => {
    const data = {};
    if (searchTerm) data.keyword = searchTerm.trim().toLowerCase();
    if (statusFilter) data.is_active = statusFilter.value;
  
    setFilterApplied(Boolean(searchTerm || statusFilter));
    getAdsList(data);
  };


  const clearAllFilters = () => {
    setStatusFilter(null);
    setFilterApplied(false);
    setShowFilters(false);
    setSearchTerm('');
    getAdsList({});
  };

  const toggleFilters = () => setShowFilters(prev => !prev);

  const adsData = ads.map(ad => [
    ad.name,
    ad.description || '-',
    new Date(ad.start_date).toLocaleDateString(),
    new Date(ad.end_date).toLocaleDateString(),
    ad.redirect_url || '-',
    ad.is_active ? 'Yes' : 'No',
  ]);

  return (
    <div className="vendor-table-container container mt-3">
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h3>Ads</h3>
          <p>Here is a list of all Ads</p>
        </div>

        <div className='mx-5 mb-4'>
          <InputGroup>
            <Form.Control
              id="search-input"
              type="search"
              placeholder="Search"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ width: '350px' }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  applyFilters();
                }
              }}
              autoComplete="off"
            />
            <InputGroup.Text className='primaryBackground' onClick={applyFilters}>
              <FaSearch className='text-white' />
            </InputGroup.Text>
          </InputGroup>
        </div>

        <div className="d-flex align-items-center mb-3">
          <img
            className='mx-2'
            src="/image/filter_active_icon.png"
            alt="Filter Applied"
            style={{ display: filterApplied ? 'block' : 'none', cursor: 'pointer' }}
          />
          <img
            className='mx-2'
            src="/image/filter_iocn.png"
            alt="Filter"
            style={{ display: filterApplied ? 'none' : 'block', cursor: 'pointer' }}
            onClick={toggleFilters}
          />
          {role == 1 &&
            <button className="btn Button primaryBackground text-white" onClick={() => navigate('/adsform/0')}>
              Add Ad
            </button>
          }
        </div>
      </div>

      {showFilters && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex gap-3">
            <DropdownButton id="status-filter" variant="outline-secondary" title={statusFilter?.name || 'Status'}>
              {statusList.map(status => (
                <Dropdown.Item key={status.name} onClick={() => setStatusFilter(status)}>
                  {status.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>

            <button className="btn Button primaryBackground text-white" onClick={applyFilters}>Apply</button>
            <button className="btn Button text-black primaryColorText btn-outline-secondary me-2 no-hover-effect" onClick={clearAllFilters}>Clear All</button>
          </div>
        </div>
      )}

      {loadingAds ? (
        <Skeleton count={5} height={40} />
      ) : (
        role == 1 ?
          <Table headers={adHeaders} data={adsData} pagination={true} recordsCount={10} edit={handleEdit} /> :
          <Table headers={adHeaders} data={adsData} pagination={true} recordsCount={10} />
      )}
    </div>
  );
};

export default AdsList;