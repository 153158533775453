import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import * as apicall from '../../components/Apicall';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Modal } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

const StaffForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { staff_data } = location.state || {};
  const { searchTerm, statusFilter, roleFilter, filterApplied } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const saveOrUpdateStaff = async (values, isActive = 1) => {
    try {
      const data = {
        id: id,
        first_name: values.name,
        middle_name: values.surname,
        last_name: values.lastName,
        email: values.email,
        mobile: values.contactNumber,
        start_date: values.startDate,
        end_date: values.endDate,
        role: values.role,
        address: values.address,
        gender: values.gender,
        is_active: isActive,
      };
      setLoading(true);
      const res = await apicall.post(data, 'staff');
      setLoading(false);
      toast.success(isActive ? res.message : 'Staff has been successfully marked as Deleted');
      if (!isActive) setShowModal(false);
      setTimeout(() => {
        navigate('/staff');
      }, 1500);
    } catch (e) {
      toast.error(e.message.replace('Error:', ''));
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('First Name is required')
      .max(50, 'First Name cannot exceed 50 characters'),
    middleName: Yup.string().max(50, 'Middle Name cannot exceed 50 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .max(50, 'Last Name cannot exceed 50 characters'),
    contactNumber: Yup.string()
      .required('Contact number is required')
      .matches(/^[0-9]*$/, 'Only digits are allowed'),
    gender: Yup.string().required('Gender is required'),
    address: Yup.string().max(100, 'Address cannot exceed 100 characters'),
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
      .required('Email is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date().min(Yup.ref('startDate'), 'End Date can’t be before Start Date'),
    role: Yup.string().required('Role is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: staff_data ? staff_data.first_name : '',
      lastName: staff_data ? staff_data.last_name : '',
      surname: staff_data ? staff_data.middle_name : '',
      email: staff_data ? staff_data.email : '',
      contactNumber: staff_data ? staff_data.phone_number : '',
      gender: staff_data?.gender == null ? '' : staff_data.gender,
      address: staff_data ? staff_data.address : '',
      startDate: staff_data
        ? new Date(staff_data.start_date).toISOString().split('T')[0]
        : '',
      endDate: staff_data
        ? new Date(staff_data.end_date).toISOString().split('T')[0]
        : '',
      role: staff_data ? staff_data.role : '',
    },
    validationSchema,
    onSubmit: (values) => {
      saveOrUpdateStaff(values);
    },
  });

  const getInputClass = (field) =>
    formik.touched[field] && formik.errors[field]
      ? 'form-control is-invalid'
      : 'form-control';

  const getSelectClass = (field) =>
    formik.touched[field] && formik.errors[field]
      ? 'form-select is-invalid'
      : 'form-select';

  return (
    <div className="container bg-white p-3 mt-4">
      <h1 className="heading mt-2">
        <span className="me-4 pointer">
          <IoArrowBack
            size={24}
            onClick={() =>
              navigate('/staff', {
                state: { searchTerm, statusFilter, roleFilter, filterApplied },
              })
            }
          />
        </span>
        {id === '0' ? 'Add a Staff' : 'Edit Staff'}
      </h1>
      <hr />
      <h1 className="heading mb-3">Basic Details</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="name" className="form-label label">
              First Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={getInputClass('name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength="40"
            />
            <div className="invalid-feedback">{formik.errors.name}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="surname" className="form-label label">
              Middle Name
            </label>
            <input
              type="text"
              id="surname"
              name="surname"
              className="form-control"
              value={formik.values.surname}
              onChange={formik.handleChange}
              maxLength="40"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="lastName" className="form-label label">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className={getInputClass('lastName')}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength="40"
            />
            <div className="invalid-feedback">{formik.errors.lastName}</div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="email" className="form-label label">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={getInputClass('email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength="40"
            />
            <div className="invalid-feedback">{formik.errors.email}</div>
          </div>
          <div className="col-md-4 mt-2">
            <label htmlFor="contact" className="label">
              Contact
            </label>
            <PhoneInput
              onlyCountries={['ae', 'in']}
              country={'ae'}
              value={formik.values.contactNumber}
              onChange={(value) => {
                formik.setFieldValue('contactNumber', value);
              }}
              inputStyle={{ width: '100%' }}
              disabled={id !== '0'}
            />
            {formik.touched.contactNumber && formik.errors.contactNumber && (
              <div className="text-danger mt-1">{formik.errors.contactNumber}</div>
            )}
          </div>
          <div className="col-md-4">
            <label htmlFor="gender" className="form-label label">
              Gender
            </label>
            <select
              id="gender"
              name="gender"
              className={getSelectClass('gender')}
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={id !== '0'}
            >
              <option value="" label="Select gender" />
              <option value="1" label="Male" />
              <option value="2" label="Female" />
              <option value="3" label="Other" />
            </select>
            <div className="invalid-feedback">{formik.errors.gender}</div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <label htmlFor="address" className="form-label label">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              className="form-control"
              value={formik.values.address}
              onChange={formik.handleChange}
              maxLength="100"
            />
          </div>

          {formik.touched.address && formik.errors.address && (
            <div className="invalid-feedback">{formik.errors.address}</div>
          )}
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="startDate" className="form-label label">
              Start Date
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              className={getInputClass('startDate')}
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={id !== '0'}
              onKeyDown={(e) => e.preventDefault()}
            />
            <div className="invalid-feedback">{formik.errors.startDate}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="endDate" className="form-label label">
              End Date
            </label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              className={getInputClass('endDate')}
              value={formik.values.endDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => e.preventDefault()}
            />
            <div className="invalid-feedback">{formik.errors.endDate}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="role" className="form-label label">
              Role
            </label>
            <select
              id="role"
              name="role"
              className={getSelectClass('role')}
              value={formik.values.role}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select role" />
              <option value="1" label="Admin" />
              <option value="2" label="Customer Support" />
            </select>
            <div className="invalid-feedback">{formik.errors.role}</div>
          </div>
        </div>

        <div className="mt-4">
          {id !== '0' && staff_data?.is_active === 1 &&(
            <>
              <button
                type="button"
                className="btn primaryColorText btn-outline-danger mb-2 mt-2"
                onClick={() => setShowModal(true)}
              >
                <FaTrash /> Delete
              </button>

              <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <div className="d-flex flex-column justify-content-center align-items-center p-1 m-5">
                  <p className="text-center mb-3">
                    Are you sure? <br />
                    You want to delete this Staff?
                  </p>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="mx-1 btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"
                      onClick={() => saveOrUpdateStaff(formik.values, 0)}
                    >
                      {loading ? 'Deleting...' : 'Delete'}
                    </button>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>

        <div className="d-flex justify-content-center mt-5 mb-4">
          <button
            type="button"
            className="btn Button primaryColorText btn-outline-secondary me-2 no-hover-effect"
            onClick={() =>
              navigate('/staff', {
                state: { searchTerm, statusFilter, roleFilter, filterApplied },
              })
            }
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn Button primaryBackground text-white position-relative d-flex justify-content-center align-items-center"
          >
            {loading ? <Loader style={{ height: '30', width: '40' }} /> : 'Save'}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default StaffForm;