import React, { useState, useEffect } from 'react';
import * as apicall from '../../components/Apicall';

const VendorPricing = ({ vendorData, vendor_id }) => {

  const [pricingData, setPricingData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [months, setMonths] = useState([
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ])


  const fetchPricingDetails = async (month) => {
    try {
      setSelectedMonth(month)
      setPricingData([])
      if (month == 0 || month != "") {
        var offering_ids = "";
        for (const file of vendorData.service_offerings) {
          offering_ids = offering_ids + (offering_ids == "" ? `` : `,`) + file.offering_id;
        }
        var data = {
          vendor_id: vendor_id,
          month: month,
          offerings: offering_ids
        }
        const res = await apicall.post(data, "vendorEditPrice");
        setPricingData(res.data);

        console.log("res.data = ", res.data)
      }
    } catch (error) {
      console.error("Failed to fetch services:", error);
    }
  };

  useEffect(() => {
    var m = new Date().getMonth()+1
    fetchPricingDetails(m);
  }, []);

  return (
    <div>
      <div className='row secondary'>
        <h1 className='sub-heading mt-3'>Price Offering For {" "}
          <select
            id="months"
            name="months"
            value={selectedMonth}
            onChange={(e) => { fetchPricingDetails(e.target.value) }}
            style={{ width: '190px' }}
          >
            {months.map(plan => (
              <option key={plan.id} value={plan.id}>{plan.name}</option>
            ))}
          </select> 
          {" "} Month</h1>

        {pricingData.map(price => (
          <div className="row py-3 border-bottom">
            <div className="col-1">
              <h1 className='content'>{price.name}:</h1>
            </div>
            {price.priceDetails.map(d => (
              <div className="col-2">
                <p className='label'>{d.service_tier_name} Fixed Price</p>
                <p className='content'>AED{d.fixed_cost}</p>
                <p className='label'>{d.service_tier_name} Per KM Price</p>
                <p className='content'>AED{d.unit_cost}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default VendorPricing